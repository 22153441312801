import React from 'react';
import RequestButton from './RequestButton.js';

const ServicesHeader = ({ children }) => {
  return (
    <div className="w-full flex flex-row justify-center items-center bg-white">
      <div className="w-full xl:w-2/3 flex flex-row flex-wrap justify-center items-start py-16 border-b">
        <div
          className="flex flex-col justify-start items-center w-1/3 mb-2"
          style={{ minWidth: '258px' }}
        >
          <p className="text-center text-gray-700 text-2xl mb-2">
            Sunstate Medical Associates
          </p>
          <p className="text-center text-gray-700 text-md">
            Internal Medicine Physicians located in Lake Mary, FL
          </p>
        </div>
        <div className="flex flex-col justify-start items-center lg:items-start w-full px-4 lg:px-10 lg:w-2/3">
          <p className="text-gray-600 text-md mb-8 text-center lg:text-left">
            {children}
          </p>
          <div className="flex flex-row flex-wrap justify-around lg:justify-start items-center w-2/3 lg:w-auto">
            <button className="bg-white flex-shrink-0 rounded text-sm text-sunstate-blue px-8 py-3 shadow-lg border border-sunstate-blue mb-2 lg:mr-4">
              407-318-2843
            </button>
            <RequestButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesHeader;
