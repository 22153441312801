import React from "react";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Conditions from "../../components/Conditions";
import ServicesHeader from "../../components/ServicesHeader";
import DermatologyInfo from "../../components/services/DermatologyInfo";

const Dermatology = () => {
  return (
    <Layout>
      <PageHeader text="Dermatology" />
      <ServicesHeader>
        Your skin is part of your identity as well as being a vital organ, so
        skin problems can cause severe psychological distress in addition to
        being uncomfortable, painful, or in some cases, life-altering. If you
        have a skin problem, the health care team at Sunstate Medical Associates
        in Lake Mary, Florida, can help. As board-certified internists, they
        have considerable skills and experience in many areas of medicine,
        including dermatology. Whether you have acne or eczema, or you’re
        worried about skin cancer, call Sunstate Medical Associates today, or
        book an appointment using the convenient form on this website.
      </ServicesHeader>
      <DermatologyInfo />
      <Conditions />
    </Layout>
  );
};

export default Dermatology;
