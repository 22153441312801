import React from "react";
import RequestButton from "../RequestButton";

const DermatologyInfo = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="w-4/5 lg:w-1/2 flex flex-col justify-start items-start py-12">
        <p className="text-3xl font-thin text-gray-800 mb-6">
          Dermatology Q & A
        </p>

        <p className="text-xl text-gray-800 mb-2">What is dermatology?</p>
        <p className="text-md text-gray-600 mb-2">
          Dermatology is the part of medicine that deals with the health of your
          skin. Dermatologists treat hair and nail conditions as well.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Skin is the body’s largest organ. It protects you from invading
          bacteria and viruses while making sure you don’t get too hot, cold,
          wet, or dry. Your skin is continually repelling attacks of one kind or
          another, so it’s not surprising that many different problems could
          affect your skin.
        </p>
        <p className="text-md text-gray-600 mb-12">
          As well as the conditions that directly affect your skin, dermatology
          concerns the care and protection of your skin. Cleansing,
          moisturizing, and preventing signs of aging are all areas that
          dermatology includes.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          What conditions does dermatology include?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Dermatology covers every type of skin, hair, or nail condition. Some
          skin conditions aren’t harmful but more of an aesthetic concern, like
          rosacea, birthmarks, melasma, and other areas of unwanted
          pigmentation.
        </p>
        <p className="text-md text-gray-600 mb-2">
          There are also fungal infections that can affect the skin, like
          athlete’s foot and ringworm, viral infections like herpes and warts,
          and contagious skin diseases like impetigo. Some of the skin
          conditions seen most commonly at Sunstate Medical Associates are:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Acne</li>
          <li>Eczema</li>
          <li>Dandruff</li>
          <li>Dermatitis</li>
          <li>Skin allergies</li>
        </ul>
        <p className="text-md text-gray-600 mb-12">
          Dermatology also deals with the diagnosis and treatment of skin
          cancer.
        </p>

        <p className="text-xl text-gray-800 mb-2">What is skin cancer?</p>
        <p className="text-md text-gray-600 mb-2">
          It’s one of the most widespread types of cancer and can be fatal, but
          skin cancer is also highly treatable if you get a prompt diagnosis.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Skin cancer typically develops because of prolonged or excessive
          exposure to ultraviolet rays from the sun. You can also overexpose
          your skin to ultraviolet if you use sunbeds too often. In Florida
          alone, around 750 people die each year from skin cancer.
        </p>
        <p className="text-md text-gray-600 mb-2">
          The most aggressive skin cancer is malignant melanoma. It typically
          develops from an existing mole, making it change in appearance or
          start bleeding or itching. If you spot a mole that looks like it’s
          changing in any way, or you see a new lesion developing, you should
          visit Sunstate Medical Associates without delay.
        </p>
        <p className="text-md text-gray-600 mb-2">
          If the team suspects you have a melanoma, they can take a sample of
          the lesion or remove it entirely before it gets a chance to spread.
        </p>
        <p className="text-md text-gray-600 mb-2">
          For all your dermatology needs from rashes to skin cancer, the
          Sunstate Medical Associates team provides expert care. They can also
          refer you to a specialist if your skin condition requires in depth
          assessment.
        </p>
        <p className="text-md text-gray-600 mb-10">
          Call Sunstate Medical Associates today for more information or book an
          appointment online.
        </p>

        <RequestButton />
      </div>
    </div>
  );
};

export default DermatologyInfo;
